.waitlist-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.waitlist-container .ant-form-item-label {
    padding: 10px !important;
}

.waitlist-container .ant-input-group-addon {
    color: white !important;
    background: var(--grey3) !important;
    border: 0;
}

.waitlist-container #handle {
    border-radius: 0 16px 16px 0 !important;
}