.ant-card-bordered {
    border-radius: 10px !important;
}

.ant-modal-content {
    /*border-radius: 10px !important;*/
    overflow: hidden !important;
    max-height: calc(100vh - 96px - 40px);
}

.ant-space-item {
    max-width: 100%;
}

.transparent-background {
    background: rgba(0, 0, 0, 0) !important;
}

.list-item-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.list-item-content-row {
    display: flex;
    flex-direction: row;
}

.list-item-content-column {
    display: flex;
    flex-direction: column;
}

.recursive-overflow-wrap > * {
    overflow-wrap: anywhere;
}


/* Chrome, Safari, Edge, Opera */
.no-number-arrows::-webkit-outer-spin-button,
.no-number-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no-number-arrows {
  -moz-appearance: textfield;
}

.uppy-Dashboard-AddFiles-info {
    display: none !important;
}
