.faq-item-container {
    background-color: var(--grey3);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 34px;
    padding-bottom: 34px;
    padding: 20px;
    border-radius: 8px;
    transition: background-color .2s ease;
}

.faq-item-container.open {
    background-color: var(--grey4);
}

.faq-item-container .question {
    font-size: 18px;
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.faq-item-container .question.open {
    color: var(--orange1);
}

.faq-item-container .answer {
    font-size: 15px;
}

.faq-item-container .caret {
    color: var(--grey6);
    transition: transform .2s;
}

.faq-item-container .caret.open {
    transform: rotate(180deg);
}
