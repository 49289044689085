.media-gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 16px 10px 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.media-gallery-wrapper {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.media-gallery-wrapper .media-wrapper {
  position: relative;
  display: inline-block;
}

.media-gallery-wrapper .media-wrapper .media {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 160px;
  aspect-ratio: 1 / 1;
  max-width: 100%;
  border-radius: 22px;
  cursor: pointer;
}

.media-gallery-wrapper .media-wrapper .center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  pointer-events: none;
}

/* for play video icon */
.media-gallery-wrapper .media-wrapper .icon svg {
  fill: var(--white) !important;
}

.media-gallery-wrapper .media-wrapper .top-right {
  position: absolute;
  right: 10px;
  top: 10px;
  pointer-events: none;
}

.media-gallery-wrapper .media-wrapper .unlock {
  padding: 10px 15px 10px 15px;
  border-radius: 70px;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
  font-weight: normal;
  white-space: nowrap;
  user-select: none;
  font-weight: bold;
}

.media-gallery-wrapper .media-wrapper .duration {
  background-color: #FFFFFF80;
  font-size: 12px;
}
