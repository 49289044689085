.policies-links {
    margin-top: 8px;
    color: var(--grey5);
    font-size: 12px;
}

.policies-links .link {
    color: var(--white);
}

.policies-links .link:hover {
    text-decoration: underline;
}
