.app-sider-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.app-sider-selected-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    text-align: center;
}

.app-sider-selected-profile .name {
    color: var(--white);
}

.app-sider-selected-profile .link {
    color: var(--grey6);
    font-size: 14px;
}
