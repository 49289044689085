.onboarding-container {
    width: 100%;
    max-width: 600px;
    padding: 15px;
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.onboarding-container .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.onboarding-container .description {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--grey6);
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.onboarding-container .ant-form-item-label {
    padding: 10px !important;
}

.onboarding-container .ant-input-group-addon {
    color: white !important;
    background: var(--grey3) !important;
    border: 0;
}

.onboarding-container #handle {
    border-radius: 0 16px 16px 0 !important;
}

.onboarding-container .form .form-button {
    background-color: var(--grey2);
}

.ant-steps-item-title {
    color: var(--grey6) !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: var(--white) !important;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: var(--grey5) !important;
}

.ant-list-split .ant-list-item {
    border-block-end: 1px solid var(--grey3);
}

.ant-list .ant-list-item .ant-list-item-action .ant-list-item-action-split {
    background-color: var(--grey4);
}
