.character-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.character-name {
    color: var(--white);
    font-size: 16px;
    font-weight: bold;
}

.character-status {
    color: var(--grey6);
    font-size: 12px;
}

.button-response-type {
    width: 30px;
    height: 32px;
    border: 0;
    background-color: var(--white30);
    color: var(--grey2);
    cursor: pointer;
}

.button-response-type:hover {
    background-color: var(--white50);
}

.button-response-type.active {
    background-color: var(--white);
    box-shadow: 0px 0px 20px 5px #FFFFFF80;
    cursor: default;
}

.button-response-type.left {
    border-radius: 20px 4px 4px 20px;
}

.button-response-type.right {
    border-radius: 4px 30px 30px 4px;
}

.subscribe-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--orange1);
    color: var(--white);
    border-radius: 90px;
    height: 42px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
}

.subscribe-button:hover {
    background-color: var(--white);
    color: var(--orange1);
    box-shadow: 0px 0px 20px 0px #FFFFFF80;
}

.chat-tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: var(--grey1);
}

.chat-tabs .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 38px; /* chat tab height */
    background-color: var(--grey2);
    color: var(--grey6);
    font-size: 14px;
    cursor: pointer;
    user-select: none;
}

.chat-tabs .tab:hover {
    border-bottom: solid 1px var(--grey6);
}

.chat-tabs .tab.active {
    border-bottom: solid 1px var(--white);
    color: var(--white);
}

.chat-tabs .tab.active {
    border-bottom: solid 1px var(--white);
    color: var(--white);
    background-color: var(--grey2);
    cursor: default;
}
