.highlights-container .highlight {
  width: 286px;
  height: 428px;
  background-color: var(--grey2);
  border: solid 1px rgba(255, 255, 255, 0.2);
  box-shadow: 0 10px 15px -3px rgba(255, 255, 255, 0.2), 0 4px 6px -4px rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.highlights-container .highlight .background {
  width: 100%;
  height: 60%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
}

.highlights-container .highlight .title {
  font-size: 20px;
  text-align: center;
}

.highlights-container .highlight .description {
  font-size: 14px;
  text-align: center;
  color: var(--grey6);
}