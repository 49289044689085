.character-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.character-profile-container .ant-form-item-label {
    padding: 10px !important;
}
