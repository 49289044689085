.index-footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.index-footer-container .title {
    font-size: 60px;
    text-align: center;
}

.index-footer-container .description {
    font-size: 20px;
    text-align: center;
    color: var(--grey6);
}

@media only screen and (max-width: 600px) {
    .index-footer-container .title {
        font-size: 40px;
    }

    .index-footer-container .description {
        font-size: 16px;
    }
}

.index-footer-container .social-media-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.index-footer-container .social-media {
    padding: 10px;
    color: var(--white);
    background-color: var(--grey3);
    border-radius: 100%;
}

.index-footer-container .divider {
    font-size: 20px;
    text-align: center;
    border-color: var(--grey4);
    width: 90%;
    max-width: 1024px;
}