.menu-drawer-container {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
}

.menu-drawer-container .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 34px;
    font-size: 22px;
    font-weight: bold;
}

.menu-drawer-container .selected-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    text-align: center;
}

.menu-drawer-container .selected-profile .name {
    color: var(--white);
}

.menu-drawer-container .selected-profile .link {
    color: var(--grey6);
    font-size: 14px;
}

.menu-drawer-container .ant-avatar {
    background-color: var(--grey1);
}