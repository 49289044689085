.highlights-container .title {
  font-size: 60px;
  text-align: center;
}

.highlights-container .description {
  font-size: 20px;
  text-align: center;
  color: var(--grey6);
}

@media only screen and (max-width: 600px) {
  .highlights-container .title {
    font-size: 40px;
  }
  
  .highlights-container .description {
    font-size: 16px;
  }
}
