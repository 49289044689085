.character-chat-details-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px;
    color: var(--white);
    background-color: var(--grey3);
}

.character-chat-details {
    display: flex;
    flex-direction: column;
}
