.character-chat-list-container {
    display: flex;
    flex-direction: column;
}

.character-chat-list-container .chat {
    background-color: var(--grey3);
    color: var(--grey6);
    padding: 10px;
    border-bottom: solid 1px black;
    cursor: pointer;
}

.character-chat-list-container .chat.selected {
    background-color: var(--grey4);
}

.character-chat-list-sort-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
