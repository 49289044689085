.personality-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.personality-container .ant-form-item-label {
    padding: 10px !important;
}

.personality-container .ant-select-selector {
    height: 100% !important;
    min-height: 40px !important;
}
