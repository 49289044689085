.creator-overview-container {
    width: 100%;
    padding: 15px;
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.creator-overview-container .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}