/* chat-input */

.chat-input-container {
  position: relative;
  width: 100%;
  max-width: 865px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chat-input {
  background-color: var(--grey4);
  color: #ffffff;
  height: 40px;
  border-radius: 16px;
  font-size: 14px;
  border: 0;
}

.chat-input:hover, .chat-input:focus, .chat-input:focus-within, .chat-input:focus-visible, .chat-input:active {
  background-color: var(--grey4);
  box-shadow: none;
}

.chat-input::placeholder {
  color: #7B7C7D;
}

.chat-input.ant-input-affix-wrapper {
  border: 0;
  border-radius: 60px;
  padding: 4px;
  padding-left: 24px;
}

.chat-input:disabled {
  background-color: var(--grey3) !important;
}

/* button */

.chat-button-circle {
  background-color: #B3B3B3;
  border: 0;
  padding: 0;
  cursor: pointer;
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chat-button-circle:not(:disabled):hover {
  background-color: var(--white);
}

.chat-button-circle:disabled {
  cursor: not-allowed;
  background-color: var(--grey3);
}

.chat-button-circle.active {
  background-color: var(--white);
  box-shadow: 0px 0px 20px 5px var(--white50);
}

.chat-button-circle.active:not(:disabled):hover {
  box-shadow: 0px 0px 20px 5px var(--white70);
}

/* for MessageSpinnerSVG */

.spinner_b2T7 {
  animation: spinner_xe7Q 0.8s linear infinite;
}

.spinner_YRVV {
  animation-delay: -0.65s;
}

.spinner_c9oY {
  animation-delay: -0.5s;
}

@keyframes spinner_xe7Q {
  46.875% {
      r: 0.2px;
  }
  93.75%, 100% {
      r: 3px;
  }
}
