.lander-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--white);
}

.lander-container .background {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.lander-container .background .gradient {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.lander-container .background .center {
  position: absolute;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lander-container .title {
  font-size: 60px;
  text-align: center;
}

.lander-container .description {
  font-size: 20px;
  text-align: center;
  color: rgb(245, 245, 245);
  line-height: 26px;
}

@media only screen and (max-width: 600px) {
  .lander-container .title {
    font-size: 40px;
  }
  
  .lander-container .description {
    font-size: 16px;
  }
}
