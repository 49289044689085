.wallet-drawer-container {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
}

.bundles-container {
    margin-bottom: 28px;
    width: calc(100% - 40px);
    padding: 0px 20px;
    justify-content: center;
}

.bundles-container.mobile {
    overflow-x: scroll;
    justify-content: initial;
}

.bundle {
    align-items: center;
    border-radius: 16px;
    background-color: var(--grey4);
    border: 2px solid var(--grey4);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: bold;
    justify-content: center;
    user-select: none;
    width: 92px;
    height: 92px;
    min-width: 92px;

}

.bundle:hover {
    background-color: var(--grey5);
    border: 2px solid var(--grey5);
}

.bundle.active {
    border: 2px solid var(--white);
    cursor: default;
}

.bundle.active:hover {
    background-color: var(--grey4);
    border: 2px solid var(--white);
}

.confirm-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--grey1);
    border-radius: 90px;
    margin: 0px 20px;
    padding: 15px;
    width: calc(100% - 40px);
    border: 0;
    color: var(--white);
    font-size: 16px;
}

.confirm-button.active {
    cursor: pointer;
    background-color: var(--white);
    color: var(--grey0);
    box-shadow: 0px 0px 20px 0px #FFFFFF80;
}

.confirm-button.active:hover {
    background-color: var(--orange1);
    color: var(--white);
    box-shadow: 0px 0px 20px 0px #FE5C0399;
}

.wallet-drawer-container .balance-title {
    color: var(--grey5);
    font-size: 14px;
}

.wallet-drawer-container .balance-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--white);
    background-color: var(--grey1);
    width: 100px;
    height: 40px;
    border-radius: 55px;
    font-size: 18px;
}
