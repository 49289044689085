.message-box-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}

.message-box-wrapper.no-margin {
  margin-top: 0px;
  margin-bottom: 0px;
}

.message-box-wrapper.left {
  margin-right: auto;     
}

.message-box-wrapper.right {
  margin-left: auto;
}

.message-box-wrapper.markdown {
  margin-left: 0;
  margin-right: 0;
}

.message-box {
  position: relative;
  border-radius: 22px;
  color: var(--grey1);
}

.message-box.non-voice {
  max-width: 340px;
}

.message-box.voice {
  width: 260px;
}

.message-box.markdown {
  max-width: 100%;
}

.message-box.text-or-voice {
  padding: 14px;
  color: var(--white);
}


.message-box.left.text-or-voice {
  background-color: var(--orange1);
}

.message-box.right.text-or-voice {
    background-color: var(--grey3);;
}

.message-box-wrapper .time {
  user-select: none;
  font-size: 14px;
  margin-top: 12px;
  width: fit-content;
  color: var(--grey5);
}

.message-box-wrapper.left .time {
  margin-right: auto;
}

.message-box-wrapper.right .time {
  margin-left: auto;
}


.message-box .notch {
  position: absolute;
  bottom: -1px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--orange1);
}

.message-box.left .notch {
  left: -1px;
  background-color: var(--orange1);
}

.message-box.right .notch {
  right: -1px;
  background-color: var(--grey3);;
}

.message-box .center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  pointer-events: none;
}

.message-box .bottom-center {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0);
  pointer-events: none;
}

.message-box .top-right {
  position: absolute;
  right: 10px;
  top: 10px;
  pointer-events: none;
}

/* for play video icon */
.message-box .icon svg {
  fill: var(--white) !important;
}

.message-box .unlock {
  padding: 10px 15px 10px 15px;
  border-radius: 70px;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
  font-weight: normal;
  white-space: nowrap;
  user-select: none;
  font-weight: bold;
}

.message-box .price {
  background-color: var(--white);
}

.message-box .instructions {
  background-color: #FFFFFF80;
}

.message-box .duration {
  background-color: #FFFFFF80;
  font-size: 12px;
}

.message-box .pointer {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.message-box .media {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 300px;
  height: 400px;
  max-width: 85vw;
  border-radius: 22px;
}

.message-box .media-wrapper {
  border-radius: 22px;
  background-color: var(--grey2);
}

.message-box .media-wrapper .text {
  width: 300px;
  padding: 16px;
  color: var(--grey6);
  font-style: italic;
}

.message-box .product-wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  width: 100%;
}

.message-box .product {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 160px;
  height: 160px;
  max-width: 85vw;
  border-radius: 22px;
}

.message-box .product-wrapper .text {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  text-align: center;
  flex: 1;
  text-wrap: wrap;
  width: 100%;
  
}

.message-box .product-wrapper .category {
  color: var(--grey6);
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
}

.message-box .product-wrapper .name {
  color: var(--white);
  padding-left: 10px;
  padding-right: 10px;
}
