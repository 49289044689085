.handle-container #handle {
    border-radius: 0 16px 16px 0 !important;
}

.handle-container .ant-form-item-label {
    padding: 10px !important;
}

.handle-container .ant-input-group-addon {
    color: white !important;
    background: var(--grey4) !important;
    border: 0;
}
