.pricing-drawer-container {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
}

.pricing-drawer-container .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 34px;
    font-size: 22px;
    font-weight: bold;
}
