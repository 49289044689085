.age-warning-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: var(--grey1);
  border-radius: 20px;
}

.age-warning-modal-container .buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
