.message-list {
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100svh - 96px - 96px - 38px); /* 38px is chat tab height */
    padding: 10px 16px 10px 16px;
}

.date {
    margin: 10px auto;
    padding: 16px;
    border-radius: 22px;
    background-color: var(--grey2);
    color: var(--grey6);
    user-select: none;
}
