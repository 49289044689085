.join-container {
    background-color: var(--grey2);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 68px;
    padding-bottom: 68px;
}

.join-container .title {
    font-size: 60px;
    text-align: center;
}

.join-container .description {
    font-size: 20px;
    text-align: center;
    color: var(--grey6);
}

@media only screen and (max-width: 600px) {
    .join-container .title {
        font-size: 40px;
    }

    .join-container .description {
        font-size: 16px;
    }
}
